import 'whatwg-fetch';

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 * @return {object}          The parsed JSON from the request
 */
const parseJSON = response => {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
};

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 * @return {object|undefined} Returns either the response, or throws an error
 */
const checkStatus = response => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText || '');
  error.status = response.status;
  error.json = response.json();
  throw error;
};

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           The response data
 */
const request = (url, options, raw = false) => {
  const fullUrl =
    url.indexOf('http') === -1 ? `${process.env.REACT_APP_API_URL}${url}` : url;
  const res = fetch(fullUrl, options);
  if (raw) {
    return res;
  }

  return res.then(checkStatus).then(parseJSON);
};

export default request;
