import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { requestDetails } from 'utils/request';

import { selectOpportunities, selectQuoteDetails } from './selectors';
import { actions } from './slice';

export function* loadOpportunitiesSaga() {
  yield call(requestDetails, {
    endpoint: '/api/dw/opportunity/list',
    onError: ({ error }) => actions.loadOpportunitiesError(error),
    onSuccess: ({ details }) => actions.loadOpportunitiesSuccess(details || []),
  });
}

export function* editOpportunitySaga({
  payload: { payload, resolve, reject },
}) {
  const opportunities = yield select(selectOpportunities);

  yield call(requestDetails, {
    body: JSON.stringify(payload),
    endpoint: '/api/sfp/composite/quote',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    onError: ({ error }) => actions.editOpportunityError(error),
    onSuccess: () =>
      actions.editOpportunitySuccess(
        opportunities.map(o =>
          o.opportunityId === payload.opportunityId
            ? {
                ...o,
                ...payload,
              }
            : o,
        ),
      ),
    resolve: () => resolve(),
    reject: ({ error }) => reject(error),
  });
}

export function* loadQuoteDetailsSaga({
  payload: { quoteId, siblings = false },
}) {
  yield call(requestDetails, {
    endpoint: `/api/dw/quote/${quoteId}${siblings ? '?siblings=true' : ''}`,
    onError: ({ error }) => actions.loadQuoteDetailsError(error),
    onSuccess: ({ details }) =>
      actions.loadQuoteDetailsSuccess({
        ...details,
        modifiedQuoteLines: [],
      }),
  });
}

export function* addQuoteDetailsSaga({
  payload: { payload, resolve, reject },
}) {
  yield call(requestDetails, {
    body: JSON.stringify(payload),
    endpoint: '/api/sfp/composite/quote',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    onError: ({ error }) => actions.addQuoteDetailsError(error),
    onSuccess: ({ details }) => actions.addQuoteDetailsSuccess(details),
    resolve: details => resolve(details),
    reject: ({ error }) => reject(error),
  });
}

export function* editQuoteDetailsSaga({
  payload: { payload, resolve, reject },
}) {
  yield call(requestDetails, {
    body: JSON.stringify(payload),
    endpoint: '/api/sfp/composite/quote',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    onError: ({ error }) => actions.editQuoteDetailsError(error),
    onSuccess: ({ details }) => actions.editQuoteDetailsSuccess(details),
    resolve: () => resolve(),
    reject: ({ error }) => reject(error),
  });
}

export function* deleteQuoteDetailsSaga({ payload: { id, resolve, reject } }) {
  yield call(requestDetails, {
    endpoint: `/api/sfp/collection/sObjects?ids=${id}`,
    method: 'DELETE',
    onError: ({ error }) => actions.deleteQuoteDetailsError(error),
    onSuccess: () => actions.deleteQuoteDetailsSuccess(),
    resolve: () => resolve(),
    reject: ({ error }) => reject(error),
  });
}

export function* addPendingQuoteLineSaga({
  payload: { payload, quoteId, resolve, reject },
}) {
  const quoteDetails = yield select(selectQuoteDetails);

  yield call(requestDetails, {
    body: JSON.stringify(payload),
    endpoint: `/api/data/cart/pendingQuoteLine/${quoteId}`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    onError: ({ error }) => actions.addPendingQuoteLineError(error),
    onSuccess: ({ details }) =>
      actions.addPendingQuoteLineSuccess({
        ...quoteDetails,
        quoteLines: [details, ...quoteDetails.quoteLines],
      }),
    resolve: details => resolve(details),
    reject: ({ error }) => reject(error),
  });
}

export function* editPendingQuoteLineSaga({
  payload: { payload, resolve, reject },
}) {
  const quoteDetails = yield select(selectQuoteDetails);

  yield call(requestDetails, {
    body: JSON.stringify(payload),
    endpoint: '/api/data/cart/pendingQuoteLine',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    onError: ({ error }) => actions.editPendingQuoteLineError(error),
    onSuccess: () =>
      actions.editPendingQuoteLineSuccess({
        ...quoteDetails,
        quoteLines: quoteDetails.quoteLines.map(ql =>
          ql.pendingId === payload.pendingId ? payload : ql,
        ),
      }),
    resolve: () => resolve(),
    reject: ({ error }) => reject(error),
  });
}

export function* bulkUpdateQuoteLinesSaga({
  payload: { pending = true, quoteId, payload, resolve, reject },
}) {
  const quoteDetails = yield select(selectQuoteDetails);

  yield call(requestDetails, {
    body: JSON.stringify(payload),
    endpoint: `${
      pending
        ? '/api/data/cart/pendingQuoteLines/'
        : '/api/sfp/collection/quoteLines/'
    }${quoteId}`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    onError: ({ error }) => actions.bulkUpdateQuoteLinesError(error),
    onSuccess: ({ details: { deleted, inserted, updated } }) =>
      actions.bulkUpdateQuoteLinesSuccess({
        ...quoteDetails,
        quoteLines: [
          ...quoteDetails.quoteLines
            .filter(
              ql => !deleted.some(d => d === ql[pending ? 'pendingId' : 'id']),
            )
            .map(
              ql =>
                updated.find(
                  u =>
                    u[pending ? 'pendingId' : 'id'] ===
                    ql[pending ? 'pendingId' : 'id'],
                ) || ql,
            ),
          ...inserted,
        ],
      }),
    resolve: () => resolve(),
    reject: ({ error }) => reject(error),
  });
}

export function* deletePendingQuoteLinesSaga({
  payload: { ids, resolve, reject },
}) {
  const quoteDetails = yield select(selectQuoteDetails);

  let query = '?';
  ids.forEach(id => (query = `${query}pendingQuoteLineId=${id}&`));

  yield call(requestDetails, {
    endpoint: `/api/data/cart/pendingQuoteLines${query.slice(0, -1)}`,
    method: 'DELETE',
    onError: ({ error }) => actions.deletePendingQuoteLinesError(error),
    onSuccess: () =>
      actions.deletePendingQuoteLinesSuccess({
        ...quoteDetails,
        quoteLines: [
          ...quoteDetails.quoteLines.filter(
            ql => !ids.some(id => id === ql.pendingId),
          ),
        ],
      }),
    resolve: () => resolve(),
    reject: ({ error }) => reject(error),
  });
}

export function* editQuoteLineSaga({ payload }) {
  const quoteDetails = yield select(selectQuoteDetails);

  yield put(
    actions.editQuoteLineSuccess({
      ...quoteDetails,
      modifiedQuoteLines: quoteDetails.modifiedQuoteLines.some(
        ql => ql.id === payload.id,
      )
        ? quoteDetails.modifiedQuoteLines.map(ql =>
            ql.id === payload.id ? { ...ql, ...payload } : ql,
          )
        : [payload, ...quoteDetails.modifiedQuoteLines],
    }),
  );
}

export function* deleteQuoteLinesSaga({ payload: { ids, resolve, reject } }) {
  const quoteDetails = yield select(selectQuoteDetails);

  let query = '?';
  ids.forEach(id => (query = `${query}quoteLineIds=${id}&`));

  yield call(requestDetails, {
    endpoint: `/api/sfp/collection/quoteLines${query.slice(0, -1)}`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'DELETE',
    onError: ({ error }) => actions.deleteQuoteLinesError(error),
    onSuccess: ({ details }) =>
      actions.deleteQuoteLinesSuccess({
        ...quoteDetails,
        quoteLines: quoteDetails.quoteLines.filter(
          ql => !details.some(id => id === ql.id),
        ),
      }),
    resolve: () => resolve(),
    reject: ({ error }) => reject(error),
  });
}

export function* finalizeModifiedQuoteLinesSaga({
  payload: {
    quoteId,
    segmentIds,
    includeModifiedQuoteLines = true,
    resolve,
    reject,
  },
}) {
  const quoteDetails = yield select(selectQuoteDetails);
  const payload = {
    insightSegments: segmentIds,
  };

  if (includeModifiedQuoteLines) {
    payload.quoteLines = quoteDetails.modifiedQuoteLines;
  }

  yield call(requestDetails, {
    body: JSON.stringify(payload),
    endpoint: `/api/sfp/composite/quoteLines/${quoteId}`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    onError: ({ error }) => actions.finalizeModifiedQuoteLinesError(error),
    onSuccess: ({ details }) =>
      actions.finalizeModifiedQuoteLinesSuccess({
        ...quoteDetails,
        ...(includeModifiedQuoteLines && { modifiedQuoteLines: [] }),
        quoteLines: details,
      }),
    resolve: () => resolve(),
    reject: ({ error }) => reject(error),
  });
}

export function* deleteModifiedQuoteLinesSaga({ payload: ids }) {
  const quoteDetails = yield select(selectQuoteDetails);

  yield put(
    actions.deleteModifiedQuoteLineSuccess({
      ...quoteDetails,
      modifiedQuoteLines: !ids
        ? []
        : quoteDetails.modifiedQuoteLines.filter(
            ql => !ids.some(id => id === ql.id),
          ),
    }),
  );
}

export function* changeQuoteLinesRevisionSaga({
  payload: { productId, quoteId, revision, resolve, reject },
}) {
  const quoteDetails = yield select(selectQuoteDetails);
  const payload = { productId, revision };

  yield call(requestDetails, {
    body: JSON.stringify(payload),
    endpoint: `/api/sfp/composite/changeRevision/${quoteId}`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    onError: ({ error }) => actions.changeQuoteLinesRevisionError(error),
    onSuccess: ({ details }) =>
      actions.changeQuoteLinesRevisionSuccess({
        ...quoteDetails,
        quoteLines: [
          ...quoteDetails.quoteLines.map(
            ql =>
              details.find(
                u =>
                  (!!u.id && u.id === ql.id) ||
                  (!!u.pendingId && u.pendingId === ql.pendingId),
              ) || ql,
          ),
        ],
      }),
    resolve: () => resolve(),
    reject: ({ error }) => reject(error),
  });
}

export function* addInsightSegmentsSaga({
  payload: { payload, quoteId, resolve, reject },
}) {
  const quoteDetails = yield select(selectQuoteDetails);

  yield call(requestDetails, {
    body: JSON.stringify(payload),
    endpoint: `/api/data/cart/insightSegments/${quoteId}`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    onError: ({ error }) => actions.addInsightSegmentsError(error),
    onSuccess: ({ details }) =>
      actions.addInsightSegmentsSuccess({
        ...quoteDetails,
        insightSegments: [...quoteDetails.insightSegments, ...details],
      }),
    resolve: () => resolve(),
    reject: ({ error }) => reject(error),
  });
}

export function* deleteInsightSegmentsSaga({
  payload: { segments, quoteId, resolve, reject },
}) {
  const quoteDetails = yield select(selectQuoteDetails);

  let query = '?';
  segments.forEach(s => (query = `${query}insightSegments=${s}&`));

  yield call(requestDetails, {
    endpoint: `/api/data/cart/insightSegments/${quoteId}${query.slice(0, -1)}`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'DELETE',
    onError: ({ error }) => actions.deleteInsightSegmentsError(error),
    onSuccess: ({ details }) =>
      actions.deleteInsightSegmentsSuccess({
        ...quoteDetails,
        insightSegments: quoteDetails.insightSegments.filter(
          s => !details.some(ds => ds === s),
        ),
      }),
    resolve: () => resolve(),
    reject: ({ error }) => reject(error),
  });
}

export function* opportunitiesSaga() {
  yield all([
    takeLatest(actions.loadOpportunities.type, loadOpportunitiesSaga),
    takeLatest(actions.editOpportunity.type, editOpportunitySaga),
    takeLatest(actions.loadQuoteDetails.type, loadQuoteDetailsSaga),
    takeLatest(actions.addQuoteDetails.type, addQuoteDetailsSaga),
    takeLatest(actions.editQuoteDetails.type, editQuoteDetailsSaga),
    takeLatest(actions.deleteQuoteDetails.type, deleteQuoteDetailsSaga),
    takeLatest(actions.addPendingQuoteLine.type, addPendingQuoteLineSaga),
    takeLatest(actions.editPendingQuoteLine.type, editPendingQuoteLineSaga),
    takeLatest(actions.bulkUpdateQuoteLines.type, bulkUpdateQuoteLinesSaga),
    takeLatest(
      actions.deletePendingQuoteLines.type,
      deletePendingQuoteLinesSaga,
    ),
    takeLatest(actions.editQuoteLine.type, editQuoteLineSaga),
    takeLatest(actions.deleteQuoteLines.type, deleteQuoteLinesSaga),
    takeLatest(
      actions.finalizeModifiedQuoteLines.type,
      finalizeModifiedQuoteLinesSaga,
    ),
    takeLatest(
      actions.deleteModifiedQuoteLines.type,
      deleteModifiedQuoteLinesSaga,
    ),
    takeLatest(
      actions.changeQuoteLinesRevision.type,
      changeQuoteLinesRevisionSaga,
    ),
    takeLatest(actions.addInsightSegments.type, addInsightSegmentsSaga),
    takeLatest(actions.deleteInsightSegments.type, deleteInsightSegmentsSaga),
  ]);
}
