import styled from 'styled-components/macro';
import getPropStyles from 'styles/getPropStyles';

const H4 = styled.h4`
  font-size: 11px;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: ${p => (p.normal ? 'normal' : '1.1px')};
  text-transform: ${p => (p.normal ? 'inherit' : 'uppercase')};
  margin: 0;
  ${p => getPropStyles(p)}
`;

export default H4;
