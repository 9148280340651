import styled from 'styled-components/macro';
import getPropStyles from 'styles/getPropStyles';

const H6 = styled.h6`
  font-size: 9px;
  font-weight: normal;
  line-height: 11px;
  letter-spacing: 1.1px;
  text-transform: uppercase;
  margin: 0;
  ${p => getPropStyles(p)}
`;

export default H6;
