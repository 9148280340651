import { all, call, select, takeLatest } from 'redux-saga/effects';
import { requestDetails } from 'utils/request';

import { selectAccountProfile } from './selectors';
import { actions } from './slice';

export function* loadAccountsSaga() {
  yield call(requestDetails, {
    endpoint: '/api/dw/account/list',
    onError: ({ error }) => actions.loadAccountsError(error),
    onSuccess: ({ details }) => actions.loadAccountsSuccess(details),
  });
}

export function* loadAccountDetailsSaga({ payload }) {
  yield call(requestDetails, {
    endpoint: `/api/dw/account/sublist?accounts=${payload}`,
    onError: ({ error }) => actions.loadAccountDetailsError(error),
    onSuccess: ({ details }) =>
      actions.loadAccountDetailsSuccess(details.length > 0 ? details[0] : {}),
  });
}

export function* loadAccountOpportunitiesSaga({ payload }) {
  yield call(requestDetails, {
    endpoint: `/api/dw/opportunity/list/${payload}`,
    onError: ({ error }) => actions.loadAccountOpportunitiesError(error),
    onSuccess: ({ details }) =>
      actions.loadAccountOpportunitiesSuccess({
        id: payload,
        opportunities: details,
      }),
  });
}

export function* loadAccountProfileSaga({ payload }) {
  yield call(requestDetails, {
    endpoint: `/api/dw/account/profile/${payload}`,
    onError: ({ error }) => actions.loadAccountProfileError(error),
    onSuccess: ({ details }) =>
      actions.loadAccountProfileSuccess({
        id: payload,
        ...details,
      }),
  });
}

export function* loadRecentAccountsSaga() {
  yield call(requestDetails, {
    endpoint: '/api/user/salesforce/recentAccount',
    onError: ({ error }) => actions.loadRecentAccountsError(error),
    onSuccess: ({ details }) => actions.loadRecentAccountsSuccess(details),
  });
}

export function* saveRecentAccountsSaga({ payload }) {
  yield call(requestDetails, {
    body: JSON.stringify(payload),
    endpoint: '/api/user/salesforce/recentAccount',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    onError: ({ error }) => actions.saveRecentAccountsError(error),
    onSuccess: ({ details }) => actions.saveRecentAccountsSuccess(details),
  });
}

export function* addAccountProfileContactSaga({
  payload: { payload, resolve, reject },
}) {
  const accountProfile = yield select(selectAccountProfile);

  yield call(requestDetails, {
    body: JSON.stringify(payload),
    endpoint: '/api/sfp/sObject/Contact',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    onError: ({ error }) => actions.addAccountProfileContactError(error),
    onSuccess: ({ details }) =>
      actions.addAccountProfileContactSuccess({
        ...accountProfile,
        contacts: [
          {
            id: details.id,
            ...payload,
          },
          ...accountProfile.contacts,
        ],
      }),
    resolve: () => resolve(),
    reject: ({ error }) => reject(error),
  });
}

export function* editAccountProfileContactSaga({
  payload: { payload, resolve, reject },
}) {
  const accountProfile = yield select(selectAccountProfile);

  yield call(requestDetails, {
    body: JSON.stringify(payload),
    endpoint: '/api/sfp/sObject/Contact',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    onError: ({ error }) => actions.editAccountProfileContactError(error),
    onSuccess: () =>
      actions.editAccountProfileContactSuccess({
        ...accountProfile,
        contacts: accountProfile.contacts.map(c =>
          c.id === payload.id ? payload : c,
        ),
      }),
    resolve: () => resolve(),
    reject: ({ error }) => reject(error),
  });
}

export function* deleteAccountProfileContactSaga({
  payload: { id, resolve, reject },
}) {
  const accountProfile = yield select(selectAccountProfile);

  yield call(requestDetails, {
    endpoint: `/api/sfp/sObject/Contact/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'DELETE',
    onError: ({ error }) => actions.deleteAccountProfileContactError(error),
    onSuccess: () =>
      actions.deleteAccountProfileContactSuccess({
        ...accountProfile,
        contacts: [...accountProfile.contacts.filter(c => c.id !== id)],
      }),
    resolve: () => resolve(),
    reject: ({ error }) => reject(error),
  });
}

export function* addAccountProfileCompetitorSaga({
  payload: { payload, resolve, reject },
}) {
  const accountProfile = yield select(selectAccountProfile);

  yield call(requestDetails, {
    body: JSON.stringify(payload),
    endpoint: '/api/sfp/sObject/AccountMapC',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    onError: ({ error }) => actions.addAccountProfileCompetitorError(error),
    onSuccess: ({ details }) => {
      const parentCompetitor = accountProfile.competitors.find(
        c => c.category === payload.category,
      );
      return actions.addAccountProfileCompetitorSuccess({
        ...accountProfile,
        competitors: parentCompetitor
          ? accountProfile.competitors.map(c => {
              return c.category === payload.category
                ? {
                    ...c,
                    children: c.children ? [...c.children, payload] : [payload],
                  }
                : c;
            })
          : [...accountProfile.competitors, payload],
      });
    },
    resolve: () => resolve(),
    reject: ({ error }) => reject(error),
  });
}

export function* editAccountProfileCompetitorSaga({
  payload: { payload, resolve, reject },
}) {
  const accountProfile = yield select(selectAccountProfile);

  yield call(requestDetails, {
    body: JSON.stringify(payload),
    endpoint: '/api/sfp/sObject/AccountMapC',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    onError: ({ error }) => actions.editAccountProfileCompetitorError(error),
    onSuccess: () => {
      return actions.editAccountProfileCompetitorSuccess({
        ...accountProfile,
        competitors: accountProfile.competitors.map(c => {
          if (c.id === payload.id) {
            return {
              ...payload,
              children: c.children,
            };
          } else {
            return {
              ...c,
              children: c.children?.map(d =>
                d.id === payload.id ? payload : d,
              ),
            };
          }
        }),
      });
    },
    resolve: () => resolve(),
    reject: ({ error }) => reject(error),
  });
}

export function* deleteAccountProfileCompetitorSaga({
  payload: { id, resolve, reject },
}) {
  const accountProfile = yield select(selectAccountProfile);

  yield call(requestDetails, {
    endpoint: `/api/sfp/sObject/AccountMapC/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'DELETE',
    onError: ({ error }) => actions.deleteAccountProfileCompetitorError(error),
    onSuccess: () => {
      return actions.deleteAccountProfileCompetitorSuccess({
        ...accountProfile,
        competitors: accountProfile.competitors.reduce(
          (filteredCompetitors, c) => {
            if (c.id !== id) {
              // if id doesn't match, return this object minus any children that match this id
              const filteredChildren = c.children?.filter(d => d.id !== id);
              return [
                ...filteredCompetitors,
                {
                  ...c,
                  ...(filteredChildren && { children: filteredChildren }),
                },
              ];
            } else {
              // else if id matches, check if has children
              return c.children
                ? [
                    // if has children, remove first child from list and promote to new "parent"
                    ...filteredCompetitors,
                    {
                      ...c.children[0],
                      category: c.category,
                      ...(c.children.slice(1).length && {
                        children: c.children.slice(1),
                      }),
                    },
                  ]
                : // otherwise return accumulator without adding this competitor to array
                  filteredCompetitors;
            }
          },
          [],
        ),
      });
    },
    resolve: () => resolve(),
    reject: ({ error }) => reject(error),
  });
}

export function* accountsSaga() {
  yield all([
    takeLatest(actions.loadAccounts.type, loadAccountsSaga),
    takeLatest(actions.loadAccountDetails.type, loadAccountDetailsSaga),
    takeLatest(
      actions.loadAccountOpportunities.type,
      loadAccountOpportunitiesSaga,
    ),
    takeLatest(actions.loadAccountProfile.type, loadAccountProfileSaga),
    takeLatest(actions.loadRecentAccounts.type, loadRecentAccountsSaga),
    takeLatest(actions.saveRecentAccounts.type, saveRecentAccountsSaga),
    takeLatest(
      actions.addAccountProfileContact.type,
      addAccountProfileContactSaga,
    ),
    takeLatest(
      actions.editAccountProfileContact.type,
      editAccountProfileContactSaga,
    ),
    takeLatest(
      actions.deleteAccountProfileContact.type,
      deleteAccountProfileContactSaga,
    ),
    takeLatest(
      actions.addAccountProfileCompetitor.type,
      addAccountProfileCompetitorSaga,
    ),
    takeLatest(
      actions.editAccountProfileCompetitor.type,
      editAccountProfileCompetitorSaga,
    ),
    takeLatest(
      actions.deleteAccountProfileCompetitor.type,
      deleteAccountProfileCompetitorSaga,
    ),
  ]);
}
