import styled from 'styled-components/macro';
import getPropStyles from 'styles/getPropStyles';

const H2 = styled.h2`
  font-size: 17px;
  font-weight: normal;
  line-height: 21px;
  margin: 0;
  ${p => getPropStyles(p)}
`;

export default H2;
