import styled from 'styled-components/macro';
import getPropStyles from 'styles/getPropStyles';

const P = styled.p`
  font-size: 13px;
  line-height: 21px;
  letter-spacing: 0.48px;
  margin: 0;
  ${p => getPropStyles(p)}
`;

export default P;
