import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  opportunities: [],
  opportunitiesLoaded: false,
  opportunitiesLoading: false,
  opportunitiesLoadError: null,
  opportunitiesSaving: false,
  quoteDetails: {},
  quoteDetailsLoaded: false,
  quoteDetailsLoading: false,
  quoteDetailsLoadError: null,
  quoteDetailsSaving: false,
};

const opportunitiesSlice = createSlice({
  name: 'opportunities',
  initialState,
  reducers: {
    // load opportunities
    loadOpportunities(state) {
      state.opportunities = [];
      state.opportunitiesLoaded = false;
      state.opportunitiesLoading = true;
      state.opportunitiesLoadError = null;
    },
    loadOpportunitiesSuccess(state, action) {
      state.opportunities = action.payload;
      state.opportunitiesLoaded = true;
      state.opportunitiesLoading = false;
    },
    loadOpportunitiesError(state, action) {
      state.opportunitiesLoadError = action.payload || null;
      state.opportunitiesLoading = false;
    },

    // edit existing quote details
    editOpportunity(state) {
      state.opportunitiesSaving = true;
    },
    editOpportunitySuccess(state, action) {
      state.opportunities = action.payload;
      state.opportunitiesSaving = false;
    },
    editOpportunityError(state) {
      state.opportunitiesSaving = false;
    },

    // load existing quote details
    loadQuoteDetails(state) {
      state.quoteDetails = {};
      state.quoteDetailsLoaded = false;
      state.quoteDetailsLoading = true;
      state.quoteDetailsLoadError = null;
    },
    loadQuoteDetailsSuccess(state, action) {
      state.quoteDetails = action.payload;
      state.quoteDetailsLoaded = true;
      state.quoteDetailsLoading = false;
    },
    loadQuoteDetailsError(state, action) {
      state.quoteDetailsLoadError = action.payload || null;
      state.quoteDetailsLoading = false;
    },

    // add new quote details
    addQuoteDetails(state) {
      state.quoteDetails = {};
      state.quoteDetailsLoaded = false;
      state.quoteDetailsLoading = true;
      state.quoteDetailsLoadError = null;
    },
    addQuoteDetailsSuccess(state, action) {
      state.quoteDetails = action.payload;
      state.quoteDetailsLoaded = true;
      state.quoteDetailsLoading = false;
    },
    addQuoteDetailsError(state, action) {
      state.quoteDetailsLoadError = action.payload || null;
      state.quoteDetailsLoading = false;
    },

    // edit existing quote details
    editQuoteDetails(state) {
      state.quoteDetailsSaving = true;
    },
    editQuoteDetailsSuccess(state, action) {
      state.quoteDetailsSaving = false;
      state.quoteDetails = action.payload;
    },
    editQuoteDetailsError(state) {
      state.quoteDetailsSaving = false;
    },

    // delete existing quote details
    deleteQuoteDetails(state) {
      state.quoteDetailsSaving = true;
    },
    deleteQuoteDetailsSuccess(state) {
      state.quoteDetails = {};
      state.quoteDetailsLoaded = false;
      state.quoteDetailsLoading = true;
      state.quoteDetailsSaving = false;
    },
    deleteQuoteDetailsError(state) {
      state.quoteDetailsSaving = false;
    },

    // post new pending quote line item
    addPendingQuoteLine(state) {
      state.quoteDetailsSaving = true;
    },
    addPendingQuoteLineSuccess(state, action) {
      state.quoteDetailsSaving = false;
      state.quoteDetails = action.payload;
    },
    addPendingQuoteLineError(state) {
      state.quoteDetailsSaving = false;
    },

    // edit existing pending quote line item
    editPendingQuoteLine(state) {
      state.quoteDetailsSaving = true;
    },
    editPendingQuoteLineSuccess(state, action) {
      state.quoteDetailsSaving = false;
      state.quoteDetails = action.payload;
    },
    editPendingQuoteLineError(state) {
      state.quoteDetailsSaving = false;
    },

    // update (add/edit/delete) quote line items
    bulkUpdateQuoteLines(state) {
      state.quoteDetailsSaving = true;
    },
    bulkUpdateQuoteLinesSuccess(state, action) {
      state.quoteDetailsSaving = false;
      state.quoteDetails = action.payload;
    },
    bulkUpdateQuoteLinesError(state) {
      state.quoteDetailsSaving = false;
    },

    // delete existing pending quote line items
    deletePendingQuoteLines(state) {
      state.quoteDetailsSaving = true;
    },
    deletePendingQuoteLinesSuccess(state, action) {
      state.quoteDetailsSaving = false;
      state.quoteDetails = action.payload;
    },
    deletePendingQuoteLinesError(state) {
      state.quoteDetailsSaving = false;
    },

    // edit existing quote line item
    editQuoteLine() {},
    editQuoteLineSuccess(state, action) {
      state.quoteDetails = action.payload;
    },

    // delete existing quote line item
    deleteQuoteLines(state) {
      state.quoteDetailsSaving = true;
    },
    deleteQuoteLinesSuccess(state, action) {
      state.quoteDetailsSaving = false;
      state.quoteDetails = action.payload;
    },
    deleteQuoteLinesError(state) {
      state.quoteDetailsSaving = false;
    },

    // save modified quote lines & add all pending quote lines to salesforce
    finalizeModifiedQuoteLines(state) {
      state.quoteDetailsSaving = true;
    },
    finalizeModifiedQuoteLinesSuccess(state, action) {
      state.quoteDetailsSaving = false;
      state.quoteDetails = action.payload;
    },
    finalizeModifiedQuoteLinesError(state) {
      state.quoteDetailsSaving = false;
    },

    // delete existing modified quote line item
    deleteModifiedQuoteLines() {},
    deleteModifiedQuoteLineSuccess(state, action) {
      state.quoteDetails = action.payload;
    },

    // change revision for all quote lines by product id
    changeQuoteLinesRevision(state) {
      state.quoteDetailsSaving = true;
    },
    changeQuoteLinesRevisionSuccess(state, action) {
      state.quoteDetailsSaving = false;
      state.quoteDetails = action.payload;
    },
    changeQuoteLinesRevisionError(state) {
      state.quoteDetailsSaving = false;
    },

    // post array of new quote insight segments
    addInsightSegments(state) {
      state.quoteDetailsSaving = true;
    },
    addInsightSegmentsSuccess(state, action) {
      state.quoteDetailsSaving = false;
      state.quoteDetails = action.payload;
    },
    addInsightSegmentsError(state) {
      state.quoteDetailsSaving = false;
    },

    // delete array of quote insight segments
    deleteInsightSegments(state) {
      state.quoteDetailsSaving = true;
    },
    deleteInsightSegmentsSuccess(state, action) {
      state.quoteDetailsSaving = false;
      state.quoteDetails = action.payload;
    },
    deleteInsightSegmentsError(state) {
      state.quoteDetailsSaving = false;
    },

    // reset store
    resetStore: () => initialState,
  },
});

export const { actions, reducer, name: sliceKey } = opportunitiesSlice;
