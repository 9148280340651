import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  segments: [],
  segmentsLoaded: false,
  segmentsLoading: false,
  segmentsLoadError: null,
  segmentDetails: {},
  segmentDetailsLoaded: false,
  segmentDetailsLoading: false,
  segmentDetailsLoadError: null,
};

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    // load segments
    loadSegments(state) {
      state.segments = [];
      state.segmentsLoaded = false;
      state.segmentsLoading = true;
      state.segmentsLoadError = null;
    },
    loadSegmentsSuccess(state, action) {
      state.segments = action.payload;
      state.segmentsLoaded = true;
      state.segmentsLoading = false;
    },
    loadSegmentsError(state, action) {
      state.segmentsLoadError = action.payload || null;
      state.segmentsLoading = false;
    },

    // load segment details
    loadSegmentDetails(state) {
      state.segmentDetails = {};
      state.segmentDetailsLoaded = false;
      state.segmentDetailsLoading = true;
      state.segmentDetailsLoadError = null;
    },
    loadSegmentDetailsSuccess(state, action) {
      state.segmentDetails = action.payload;
      state.segmentDetailsLoaded = true;
      state.segmentDetailsLoading = false;
    },
    loadSegmentDetailsError(state, action) {
      state.segmentDetailsLoadError = action.payload || null;
      state.segmentDetailsLoading = false;
    },

    // reset store
    resetStore: () => initialState,
  },
});

export const { actions, reducer, name: sliceKey } = productsSlice;
