import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components/macro';

import Img from 'app/components/Img';
import LoadingImg from 'images/loading.svg';

const spinning = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const LoadingWrapper = styled.div`
  position: ${p => p.position};
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
  background: ${p =>
    p.bgColor && p.theme.color[p.bgColor]
      ? p.theme.color[p.bgColor]
      : p.theme.color.manatee100};

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100px;
    height: 100px;
    margin-top: -50px; /* half */
    margin-left: -50px; /* half */
    animation: ${spinning} 4s linear infinite;
  }
`;

const LoadingOverlay = ({ bgColor, position }) => {
  return (
    <LoadingWrapper {...{ bgColor, position }}>
      <Img src={LoadingImg} />
    </LoadingWrapper>
  );
};

LoadingOverlay.defaultProps = {
  position: 'fixed',
};

LoadingOverlay.propTypes = {
  bgColor: PropTypes.string,
  position: PropTypes.oneOf(['absolute', 'fixed']),
};

export default LoadingOverlay;
