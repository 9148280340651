import { createSelector } from '@reduxjs/toolkit';

import { initialState, sliceKey } from './slice';

const selectDomain = state => state[sliceKey] || initialState;

export const selectUser = createSelector([selectDomain], state => state.user);

export const selectUserAuthenticated = createSelector(
  [selectDomain],
  state => state.userAuthenticated,
);

export const selectUserSignedOut = createSelector(
  [selectDomain],
  state => state.userSignedOut,
);

export const selectUserLoading = createSelector(
  [selectDomain],
  state => state.userLoading,
);

export const selectUserLoadError = createSelector(
  [selectDomain],
  state => state.userLoadError,
);

export const selectUserId = createSelector(
  [selectDomain],
  state => state.user.id,
);

export const selectUserLanguage = createSelector(
  [selectDomain],
  state => state.user.language,
);

export const selectUserToken = createSelector(
  [selectDomain],
  state => state.user.token,
);

/* Authority-checking utility function (reduces boilerplate in authority selectors) */
const checkUserAuthority = (state, authority) => {
  if (state.user && state.user.authorities) {
    return state.user.authorities.some(a => a.authority === authority);
  }

  return false;
};

export const selectUserIsAdmin = createSelector([selectDomain], state =>
  checkUserAuthority(state, 'admStandard'),
);
