/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

import { GlobalStyle } from 'styles/global-styles';
import primaryTheme from 'styles/theme/primary';

import ToastNotifications from 'app/components/Toast';

import LoginPage from 'app/containers/LoginPage/Loadable';
import ErrorPage from 'app/containers/ErrorPage/Loadable';
import NotFoundPage from 'app/containers/NotFoundPage/Loadable';
import PrivateRoute from 'app/containers/PrivateRoute/Loadable';

import AuthContainer from './AuthContainer/Loadable';
import ErrorBoundary from './ErrorBoundary';

Modal.defaultStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 999,
  },
};

export const App = () => {
  const { i18n } = useTranslation();

  return (
    <BrowserRouter
      getUserConfirmation={() => {
        /* Empty callback to override the default browser prompt */
      }}
    >
      <Helmet
        titleTemplate="%s - BVI Cross Sell"
        defaultTitle="BVI Cross Sell"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="BVI Cross Sell application" />
      </Helmet>

      <ThemeProvider theme={primaryTheme}>
        <ErrorBoundary>
          <Switch>
            <PrivateRoute path="/m" component={AuthContainer} />
            <Route exact path="/error" component={ErrorPage} />
            <Route exact path="/" component={LoginPage} />
            <Route component={NotFoundPage} />
          </Switch>
          <GlobalStyle />
          <ToastNotifications />
        </ErrorBoundary>
      </ThemeProvider>
    </BrowserRouter>
  );
};
