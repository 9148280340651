import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

// import reducers (ctrl+f "SETUP REDUCERS")
import {
  reducer as userReducer,
  sliceKey as userSliceKey,
} from 'app/data/user/slice';
import {
  reducer as settingsReducer,
  sliceKey as settingsSliceKey,
} from 'app/data/settings/slice';
import {
  reducer as opportunitiesReducer,
  sliceKey as opportunitiesSliceKey,
} from 'app/data/opportunities/slice';
import {
  reducer as productsReducer,
  sliceKey as productsSliceKey,
} from 'app/data/products/slice';
import {
  reducer as packsReducer,
  sliceKey as packsSliceKey,
} from 'app/data/packs/slice';
import {
  reducer as pickListsReducer,
  sliceKey as pickListsSliceKey,
} from 'app/data/pickLists/slice';
import {
  reducer as accountsReducer,
  sliceKey as accountsSliceKey,
} from 'app/data/accounts/slice';

// import sagas (ctrl+f "SETUP SAGAS")
import { userSaga } from 'app/data/user/saga';
import { accountsSaga } from 'app/data/accounts/saga';
import { opportunitiesSaga } from 'app/data/opportunities/saga';
import { packsSaga } from 'app/data/packs/saga';
import { pickListsSaga } from 'app/data/pickLists/saga';
import { productsSaga } from 'app/data/products/saga';

function createReducer(asyncReducers = {}) {
  // SETUP REDUCERS HERE
  const rootReducer = combineReducers({
    [accountsSliceKey]: accountsReducer,
    [opportunitiesSliceKey]: opportunitiesReducer,
    [packsSliceKey]: packsReducer,
    [pickListsSliceKey]: pickListsReducer,
    [productsSliceKey]: productsReducer,
    [settingsSliceKey]: settingsReducer,
    [userSliceKey]: userReducer,
    ...asyncReducers,
  });

  return rootReducer;
}

// main redux config function that's passed back to app.js
export default function configureStore() {
  let composeEnhancers = compose;
  const reduxSagaMonitorOptions = {};

  // enable Redux Dev Tools if installed
  if (process.env.NODE_ENV !== 'production' && typeof window === 'object') {
    if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});
  }

  // define individual middlewares
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);

  // create middlewares array & apply them
  const middlewares = [sagaMiddleware];
  const enhancers = [applyMiddleware(...middlewares)];

  // it's alive!
  const store = createStore(
    createReducer(),
    {},
    composeEnhancers(...enhancers),
  );

  // applying extensions
  store.runSaga = sagaMiddleware.run;

  // SETUP SAGAS HERE
  store.runSaga(userSaga);
  store.runSaga(accountsSaga);
  store.runSaga(opportunitiesSaga);
  store.runSaga(packsSaga);
  store.runSaga(pickListsSaga);
  store.runSaga(productsSaga);

  return store;
}
