import { createSelector } from '@reduxjs/toolkit';

import { initialState, sliceKey } from './slice';

const selectDomain = state => state[sliceKey] || initialState;

export const selectOpportunities = createSelector(
  [selectDomain],
  state => state.opportunities,
);

export const selectOpportunitiesLoaded = createSelector(
  [selectDomain],
  state => state.opportunitiesLoaded,
);

export const selectOpportunitiesLoading = createSelector(
  [selectDomain],
  state => state.opportunitiesLoading,
);

export const selectOpportunitiesLoadError = createSelector(
  [selectDomain],
  state => state.opportunitiesLoadError,
);

export const selectOpportunitiesSaving = createSelector(
  [selectDomain],
  state => state.opportunitiesSaving,
);

export const selectQuoteDetails = createSelector(
  [selectDomain],
  state => state.quoteDetails,
);

export const selectQuoteDetailsQuoteId = createSelector(
  [selectDomain],
  state => state.quoteDetails.quoteId,
);

export const selectQuoteDetailsCurrencyIsoCode = createSelector(
  [selectDomain],
  state => state.quoteDetails.currencyIsoCode,
);

export const selectQuoteDetailsQuoteLines = createSelector(
  [selectDomain],
  state => state.quoteDetails.quoteLines,
);

export const selectQuoteDetailsModifiedQuoteLines = createSelector(
  [selectDomain],
  state => state.quoteDetails.modifiedQuoteLines,
);

export const selectQuoteDetailsLoaded = createSelector(
  [selectDomain],
  state => state.quoteDetailsLoaded,
);

export const selectQuoteDetailsLoading = createSelector(
  [selectDomain],
  state => state.quoteDetailsLoading,
);

export const selectQuoteDetailsSaving = createSelector(
  [selectDomain],
  state => state.quoteDetailsSaving,
);

export const selectQuoteDetailsLoadError = createSelector(
  [selectDomain],
  state => state.quoteDetailsLoadError,
);
