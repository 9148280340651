function getOpacityStyles(props) {
  if (props.opacity) {
    return `opacity: ${parseFloat(props.opacity) * 0.25};`;
  }

  return '';
}

function getFontColorStyles(props) {
  if (props.fontColor && !!props.theme.color[props.fontColor]) {
    return `color: ${props.theme.color[props.fontColor]};`;
  }

  return '';
}

function getFontFamilyStyles(props) {
  if (props.bold) {
    return `font-family: ${props.theme.fonts.gilroyBold};`;
  }

  if (props.extraBold) {
    return `font-family: ${props.theme.fonts.gilroyExtraBold};`;
  }

  return '';
}

function getFontStyleStyles(props) {
  if (props.fontStyle) {
    return `font-style: ${props.fontStyle};`;
  }

  return '';
}

function getTextAlignStyles(props) {
  if (props.textAlign) {
    return `text-align: ${props.textAlign};`;
  }

  return '';
}

function getTextTransformStyles(props) {
  if (props.textTransform) {
    return `text-transform: ${props.textTransform};`;
  }

  return '';
}

export function getSingleLineStyles(props) {
  if (props.singleLine) {
    return `
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `;
  }

  return '';
}

function getPropStyles(props) {
  let styles = '';
  styles += getOpacityStyles(props);
  styles += getFontColorStyles(props);
  styles += getFontFamilyStyles(props);
  styles += getFontStyleStyles(props);
  styles += getTextAlignStyles(props);
  styles += getTextTransformStyles(props);
  styles += getSingleLineStyles(props);
  return styles;
}

export default getPropStyles;
