import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';
import Modal from 'react-modal';

// Use consistent styling
import 'sanitize.css/sanitize.css';

// Import root app & utils
import { App } from 'app/containers/App';

// Load locally-stored fonts
import 'styles/fonts/fonts.css';

// Initialize languages
import './locales/i18n';

// Redux helpers
import configureStore from './store';

const store = configureStore();
const MOUNT_NODE = document.getElementById('root');

// Tell react-modal where our app lives
// http://reactcommunity.org/react-modal/accessibility/
Modal.setAppElement(MOUNT_NODE);

ReactDOM.render(
  <Provider store={store}>
    <HelmetProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </HelmetProvider>
  </Provider>,
  MOUNT_NODE,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
