import styled from 'styled-components/macro';
import getPropStyles from 'styles/getPropStyles';

const Code = styled.code`
  font-family: monospace;
  font-size: 1.125em; /* a tiny bit larger than surrounding text */
  ${p => getPropStyles(p)}
`;

export default Code;
