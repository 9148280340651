import { call, put, select } from 'redux-saga/effects';

import { actions as userActions } from 'app/data/user/slice';
import { selectUserToken } from 'app/data/user/selectors';

import request from './request';

export function* requestDetails({
  auth = true,
  body,
  endpoint,
  headers = {},
  method = 'GET',
  onError,
  onSuccess,
  resolve,
  reject,
  transformDetails,
}) {
  try {
    const requestHeaders = {
      ...headers,
    };

    if (auth) {
      const userToken = yield select(selectUserToken);
      requestHeaders.Authorization = `Bearer ${userToken}`;
    }

    const details = yield call(request, endpoint, {
      method,
      headers: new Headers(requestHeaders),
      body,
    });

    const finalDetails = transformDetails ? transformDetails(details) : details;

    yield put(
      onSuccess({
        details: finalDetails,
      }),
    );

    if (resolve) resolve(finalDetails);
  } catch ({ json, status }) {
    if (auth && status === 401) {
      yield put(userActions.checkUser());
    }

    if (json) {
      const jsonObj = yield json;
      const errorObj = { error: jsonObj.message || jsonObj.error };
      yield put(onError(errorObj));
      if (reject) reject(errorObj);
    } else {
      const errorObj = { error: 'Error!' };
      yield put(onError(errorObj));
      if (reject) reject(errorObj);
    }
  }
}
