import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

/**
 * IMPORTANT TODO:
 * Use hooks when one is available to replace `componentDidCatch`
 */

class ErrorBoundary extends React.Component {
  componentDidCatch(error) {
    const { history } = this.props;

    // TODO: something more than just a console error?
    console.error('error!', error); // eslint-disable-line no-console

    // redirect to appropriate error page
    history.push('/error');
  }

  render() {
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(ErrorBoundary);
