import { all, call, select, takeLatest } from 'redux-saga/effects';
import { requestDetails } from 'utils/request';

import { selectRevisionDetailsPack } from './selectors';
import { actions } from './slice';

export function* loadRevisionDetailsSaga({ payload: { revision } }) {
  yield call(requestDetails, {
    endpoint: `/api/config/pack/revision/${revision}`,
    onError: ({ error }) => actions.loadRevisionDetailsError(error),
    onSuccess: ({ details }) => actions.loadRevisionDetailsSuccess(details),
  });
}

export function* addRevisionSaga({ payload: { payload, resolve, reject } }) {
  yield call(requestDetails, {
    body: JSON.stringify(payload),
    endpoint: '/api/config/pack/insert',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    onError: ({ error }) => actions.addRevisionError(error),
    onSuccess: ({ details }) => actions.addRevisionSuccess(details),
    resolve: details => resolve(details),
    reject: ({ error }) => reject(error),
  });
}

export function* addRevisionPackLayerSaga({
  payload: { configPackId, section, reject, resolve },
}) {
  const payload = {
    section,
  };

  yield call(requestDetails, {
    body: JSON.stringify(payload),
    endpoint: `/api/config/pack/assembly/layer/${configPackId}`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    onError: ({ error }) => actions.addRevisionPackLayerError(error),
    onSuccess: ({ details }) => actions.addRevisionPackLayerSuccess(details),
    resolve: () => resolve(),
    reject: ({ error }) => reject(error),
  });
}

export function* updateRevisionPackLayerSaga({ payload: { layer, reject } }) {
  const packDetails = yield select(selectRevisionDetailsPack);
  const payload = {
    configPackId: packDetails.configPackId,
    layers: {
      ...packDetails.layers,
      [layer.id]: layer,
    },
  };

  yield call(requestDetails, {
    body: JSON.stringify(payload),
    endpoint: '/api/config/pack/assembly/layout',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    onError: ({ error }) => actions.updateRevisionPackLayerError(error),
    onSuccess: ({ details }) => actions.updateRevisionPackLayerSuccess(details),
    reject: ({ error }) => reject(error),
  });
}

export function* removeRevisionPackLayerSaga({
  payload: { configPackId, layerId, section, reject, resolve },
}) {
  yield call(requestDetails, {
    endpoint: `/api/config/pack/assembly/layer/${configPackId}/${layerId}`,
    method: 'DELETE',
    onError: ({ error }) => actions.removeRevisionPackLayerError(error),
    onSuccess: ({ details }) => actions.removeRevisionPackLayerSuccess(details),
    resolve: details =>
      resolve({
        sectionLayerCount: Object.values(
          details.packs?.[0]?.layers || {},
        ).filter(l => !!l.section && l.section === section).length,
      }),
    reject: ({ error }) => reject(error),
  });
}

export function* addRevisionPackContainerSaga({
  payload: { configPackId, payload, reject, resolve },
}) {
  yield call(requestDetails, {
    body: JSON.stringify(payload),
    endpoint: `/api/config/pack/assembly/container/${configPackId}`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    onError: ({ error }) => actions.addRevisionPackContainerError(error),
    onSuccess: ({ details }) =>
      actions.addRevisionPackContainerSuccess(details),
    resolve: () => resolve(),
    reject: ({ error }) => reject(error),
  });
}

export function* removeRevisionPackContainerSaga({
  payload: { configPackId, layerId, section, reject, resolve },
}) {
  yield call(requestDetails, {
    endpoint: `/api/config/pack/assembly/container/${configPackId}/${layerId}`,
    method: 'DELETE',
    onError: ({ error }) => actions.removeRevisionPackContainerError(error),
    onSuccess: ({ details }) =>
      actions.removeRevisionPackContainerSuccess(details),
    resolve: details =>
      resolve({
        sectionLayerCount: Object.values(
          details.packs?.[0]?.layers || {},
        ).filter(l => !!l.section && l.section === section).length,
      }),
    reject: ({ error }) => reject(error),
  });
}

export function* updateRevisionPackSettingsSaga({
  payload: { settings, resolve, reject },
}) {
  const packDetails = yield select(selectRevisionDetailsPack);
  const payload = {
    configPackId: packDetails.configPackId,
    wraps: {
      double: packDetails.double,
      pack: packDetails.pack,
      trays: packDetails.trays,
      salesNotes: packDetails.salesNotes,
      ...settings,
    },
  };

  yield call(requestDetails, {
    body: JSON.stringify(payload),
    endpoint: '/api/config/pack/assembly/settings',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    onError: ({ error }) => actions.updateRevisionPackSettingsError(error),
    onSuccess: ({ details }) =>
      actions.updateRevisionPackSettingsSuccess(details),
    resolve: () => resolve(),
    reject: ({ error }) => reject(error),
  });
}

export function* updateRevisionPackGroupingSaga({
  payload: { configPackId, payload, resolve, reject },
}) {
  yield call(requestDetails, {
    body: JSON.stringify(payload),
    endpoint: `/api/config/pack/assembly/groupings/${configPackId}`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    onError: ({ error }) => actions.updateRevisionPackGroupingError(error),
    onSuccess: ({ details }) =>
      actions.updateRevisionPackGroupingSuccess(details),
    resolve: () => resolve(),
    reject: ({ error }) => reject(error),
  });
}

export function* updateRevisionPartQtySaga({
  payload: { payload, resolve, reject },
}) {
  // Payload should contain: configPackId, configPartId, quantity
  yield call(requestDetails, {
    body: JSON.stringify(payload),
    endpoint: '/api/config/pack/part',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    onError: ({ error }) => actions.updateRevisionPartQtyError(error),
    onSuccess: ({ details }) => actions.updateRevisionPartQtySuccess(details),
    resolve: () => resolve(),
    reject: ({ error }) => reject(error),
  });
}

export function* loadRevisionProductsSaga({ payload: { revision } }) {
  yield call(requestDetails, {
    endpoint: `/api/config/products/${revision}`,
    onError: ({ error }) => actions.loadRevisionProductsError(error),
    onSuccess: ({ details }) =>
      actions.loadRevisionProductsSuccess({
        revision,
        ...details,
      }),
  });
}

export function* loadPackTypesSaga() {
  yield call(requestDetails, {
    endpoint: '/api/config/lookup/packTypes',
    onError: ({ error }) => actions.loadPackTypesError(error),
    onSuccess: ({ details }) => actions.loadPackTypesSuccess(details),
  });
}

export function* loadProceduresSaga() {
  yield call(requestDetails, {
    endpoint: '/api/data/config/procedures',
    onError: ({ error }) => actions.loadProceduresError(error),
    onSuccess: ({ details: { data } }) => actions.loadProceduresSuccess(data),
  });
}

export function* loadPackTemplatesSaga({
  payload: { payload, resolve, reject },
}) {
  // Payload should contain: accountId, packTypeId, procedureTypeId
  yield call(requestDetails, {
    body: JSON.stringify(payload),
    endpoint: '/api/config/pack/templates',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    onError: ({ error }) => actions.loadPackTemplatesError(error),
    onSuccess: ({ details }) =>
      actions.loadPackTemplatesSuccess({
        templates: details,
        ...payload,
      }),
    resolve: () => resolve(),
    reject: ({ error }) => reject(error),
  });
}

export function* packsSaga() {
  yield all([
    takeLatest(actions.loadRevisionDetails.type, loadRevisionDetailsSaga),
    takeLatest(actions.addRevision.type, addRevisionSaga),
    takeLatest(actions.addRevisionPackLayer.type, addRevisionPackLayerSaga),
    takeLatest(
      actions.updateRevisionPackLayer.type,
      updateRevisionPackLayerSaga,
    ),
    takeLatest(
      actions.removeRevisionPackLayer.type,
      removeRevisionPackLayerSaga,
    ),
    takeLatest(
      actions.addRevisionPackContainer.type,
      addRevisionPackContainerSaga,
    ),
    takeLatest(
      actions.removeRevisionPackContainer.type,
      removeRevisionPackContainerSaga,
    ),
    takeLatest(
      actions.updateRevisionPackSettings.type,
      updateRevisionPackSettingsSaga,
    ),
    takeLatest(
      actions.updateRevisionPackGrouping.type,
      updateRevisionPackGroupingSaga,
    ),
    takeLatest(actions.updateRevisionPartQty.type, updateRevisionPartQtySaga),
    takeLatest(actions.loadRevisionProducts.type, loadRevisionProductsSaga),
    takeLatest(actions.loadPackTypes.type, loadPackTypesSaga),
    takeLatest(actions.loadProcedures.type, loadProceduresSaga),
    takeLatest(actions.loadPackTemplates.type, loadPackTemplatesSaga),
  ]);
}
