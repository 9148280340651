const theme = {
  /* all theme colors should be entered in `name###` format */
  color: {
    /* bedrock */
    bedrock100: '#D9D9DB',
    bedrock200: 'rgba(46, 53, 64, 0.25)',
    bedrock300: '#A5A8AC',
    bedrock400: 'rgba(46, 53, 64, 0.5)',
    bedrock500: '#727881',
    bedrock600: 'rgba(46, 53, 64, 0.75)',
    bedrock700: '#353D4A',
    bedrock800: '#2E3540',
    bedrock900: '#20252C',

    /* manatee */
    manatee100: 'rgba(255, 255, 255, 0.5)',
    manatee200: '#FFFFFF',
    manatee300: 'rgba(37, 48, 72, 0.03)',
    manatee400: '#F5F6F6',
    manatee500: '#ECECED',
    manatee600: '#CECFD1',
    manatee800: '#979AA0',

    /* havelock */
    havelock000: '#F9FBFD',
    havelock100: '#EDF2F8',
    havelock200: '#E4EBF7',
    havelock300: 'rgba(75, 117, 200, 0.25)',
    havelock400: 'rgba(75, 117, 200, 0.5)',
    havelock500: '#5589DC',
    havelock600: '#4B75C8',

    /* limerick */
    limerick200: '#CEF1D3',
    limerick400: '#4CD75E',
    limerick500: '#269C35',
    limerick600: '#1F812C',
    limerick700: '#1C7327',
    limerick800: '#196522',

    /* charlotte */
    charlotte400: '#B0D9EB',
    charlotte600: '#88D6F4',

    /* coral */
    coral200: '#FFEFEF',
    coral300: 'rgba(255, 98, 95, 0.2)',
    coral600: '#FF625F',
    coral700: '#E04E47',

    /* astronaut */
    astronaut600: '#013761',

    /* atlantis */
    atlantis600: '#A3D146',

    /* sahara */
    sahara200: '#FFF8D9',
    sahara400: '#FFCF00',
    sahara500: '#DA9C08',
    sahara600: '#B78823',
  },
  fonts: {
    gilroyMedium: "'gilroyMedium', Helvetica, Arial, sans-serif",
    gilroyBold: "'gilroyBold', Helvetica, Arial, sans-serif",
    gilroyExtraBold: "'gilroyExtraBold', Helvetica, Arial, sans-serif",
  },
  dimensions: {
    pageMaxWidth: '1500px',
  },
};

export default theme;
