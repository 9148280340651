import { createSelector } from '@reduxjs/toolkit';

import { initialState, sliceKey } from './slice';

const selectDomain = state => state[sliceKey] || initialState;

export const selectRevisionDetails = createSelector(
  [selectDomain],
  state => state.revisionDetails,
);

export const selectRevisionDetailsRevision = createSelector(
  [selectDomain],
  state => state.revisionDetails.revision,
);

export const selectRevisionDetailsPackType = createSelector(
  [selectDomain],
  state => state.revisionDetails.packType,
);

export const selectRevisionDetailsPack = createSelector([selectDomain], state =>
  !!state.revisionDetails?.packs?.length
    ? state.revisionDetails.packs[0]
    : undefined,
);

export const selectRevisionDetailsPackLayers = createSelector(
  [selectDomain],
  state =>
    !!state.revisionDetails?.packs?.length
      ? state.revisionDetails.packs[0].layers
      : undefined,
);

export const selectRevisionDetailsPackParts = createSelector(
  [selectDomain],
  state =>
    !!state.revisionDetails?.packs?.length
      ? state.revisionDetails.packs[0].parts
      : undefined,
);

export const selectRevisionDetailsPackConfigId = createSelector(
  [selectDomain],
  state =>
    !!state.revisionDetails?.packs?.length
      ? state.revisionDetails.packs[0].configPackId
      : undefined,
);

export const selectRevisionDetailsLoaded = createSelector(
  [selectDomain],
  state => state.revisionDetailsLoaded,
);

export const selectRevisionDetailsLoading = createSelector(
  [selectDomain],
  state => state.revisionDetailsLoading,
);

export const selectRevisionDetailsLoadError = createSelector(
  [selectDomain],
  state => state.revisionDetailsLoadError,
);

export const selectRevisionDetailsSaving = createSelector(
  [selectDomain],
  state => state.revisionDetailsSaving,
);

export const selectRevisionProducts = createSelector(
  [selectDomain],
  state => state.revisionProducts,
);

export const selectRevisionProductsRevision = createSelector(
  [selectDomain],
  state => state.revisionProducts.revision,
);

export const selectRevisionProductsLoaded = createSelector(
  [selectDomain],
  state => state.revisionProductsLoaded,
);

export const selectRevisionProductsLoading = createSelector(
  [selectDomain],
  state => state.revisionProductsLoading,
);

export const selectRevisionProductsLoadError = createSelector(
  [selectDomain],
  state => state.revisionProductsLoadError,
);

export const selectPackTypes = createSelector(
  [selectDomain],
  state => state.packTypes,
);

export const selectPackTypesLoaded = createSelector(
  [selectDomain],
  state => state.packTypesLoaded,
);

export const selectPackTypesLoading = createSelector(
  [selectDomain],
  state => state.packTypesLoading,
);

export const selectPackTypesLoadError = createSelector(
  [selectDomain],
  state => state.packTypesLoadError,
);

export const selectProcedures = createSelector(
  [selectDomain],
  state => state.procedures,
);

export const selectProceduresLoaded = createSelector(
  [selectDomain],
  state => state.proceduresLoaded,
);

export const selectProceduresLoading = createSelector(
  [selectDomain],
  state => state.proceduresLoading,
);

export const selectProceduresLoadError = createSelector(
  [selectDomain],
  state => state.proceduresLoadError,
);

export const selectPackTemplates = createSelector(
  [selectDomain],
  state => state.packTemplates,
);

export const selectPackTemplatesLoaded = createSelector(
  [selectDomain],
  state => state.packTemplatesLoaded,
);

export const selectPackTemplatesLoading = createSelector(
  [selectDomain],
  state => state.packTemplatesLoading,
);

export const selectPackTemplatesLoadError = createSelector(
  [selectDomain],
  state => state.packTemplatesLoadError,
);
