import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  accountsPage: {
    tableSize: 50,
  },
  financialsPage: {
    state: {
      editedQuoteLines: {},
      requiresApproval: false,
    },
  },
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    // accounts page: table page size
    setAccountsPageTableSize(state, action) {
      state.accountsPage.tableSize = action.payload;
    },

    // financials page: set edited quote lines
    setFinancialsPageEditedQuoteLines(state, action) {
      state.financialsPage.state.editedQuoteLines = action.payload;
    },

    // financials page: set requires authorization
    setFinancialsPageRequiresApproval(state, action) {
      state.financialsPage.state.requiresApproval = action.payload;
    },

    // financials page: reset state
    resetFinancialsPage: state => {
      state.financialsPage.state = initialState.financialsPage.state;
    },

    // reset store
    resetStore: () => initialState,
  },
});

export const { actions, reducer, name: sliceKey } = settingsSlice;
