import styled from 'styled-components/macro';
import getPropStyles from 'styles/getPropStyles';

const Strong = styled.strong`
  font-family: ${p => p.theme.fonts.gilroyBold};
  font-weight: normal; /* boldness is handled by font-family */
  ${p => getPropStyles(p)}
`;

export default Strong;
