import { all, call, takeLatest } from 'redux-saga/effects';
import { requestDetails } from 'utils/request';

import { actions } from './slice';

export function* loadPickListsSaga({ payload: { parameters, pickLists } }) {
  let query = '?';

  if (parameters) {
    parameters.forEach(p => (query = `${query}${p.name}=${p.value}&`));
  }

  if (pickLists) {
    pickLists.forEach(p => (query = `${query}picklists=${p}&`));
  }

  yield call(requestDetails, {
    endpoint: `/api/dw/picklists${query.slice(0, -1)}`,
    onError: ({ error }) => actions.loadPickListsError(error),
    onSuccess: ({ details }) =>
      actions.loadPickListsSuccess({
        ...parameters,
        ...details,
      }),
  });
}

export function* pickListsSaga() {
  yield all([takeLatest(actions.loadPickLists.type, loadPickListsSaga)]);
}
