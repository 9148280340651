import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  revisionDetails: {},
  revisionDetailsLoaded: false,
  revisionDetailsLoading: false,
  revisionDetailsLoadError: null,
  revisionDetailsSaving: false,
  revisionProducts: {},
  revisionProductsLoaded: false,
  revisionProductsLoading: false,
  revisionProductsLoadError: null,
  packTypes: [],
  packTypesLoaded: false,
  packTypesLoading: false,
  packTypesLoadError: null,
  procedures: [],
  proceduresLoaded: false,
  proceduresLoading: false,
  proceduresLoadError: null,
  packTemplates: {},
  packTemplatesLoaded: false,
  packTemplatesLoading: false,
  packTemplatesLoadError: null,
};

const packsSlice = createSlice({
  name: 'packs',
  initialState,
  reducers: {
    // load pack revision details
    loadRevisionDetails(state) {
      state.revisionDetails = {};
      state.revisionDetailsLoaded = false;
      state.revisionDetailsLoading = true;
      state.revisionDetailsLoadError = null;
    },
    loadRevisionDetailsSuccess(state, action) {
      state.revisionDetails = action.payload;
      state.revisionDetailsLoaded = true;
      state.revisionDetailsLoading = false;
    },
    loadRevisionDetailsError(state, action) {
      state.revisionDetailsLoadError = action.payload || null;
      state.revisionDetailsLoading = false;
    },

    // create new pack revision
    addRevision(state) {
      state.revisionDetails = {};
      state.revisionDetailsLoaded = false;
      state.revisionDetailsLoading = true;
      state.revisionDetailsLoadError = null;
    },
    addRevisionSuccess(state, action) {
      state.revisionDetails = action.payload;
      state.revisionDetailsLoaded = true;
      state.revisionDetailsLoading = false;
    },
    addRevisionError(state, action) {
      state.revisionDetailsLoadError = action.payload || null;
      state.revisionDetailsLoading = false;
    },

    // update pack revision settings
    updateRevisionPackSettings(state) {
      state.revisionDetailsSaving = true;
    },
    updateRevisionPackSettingsSuccess(state, action) {
      state.revisionDetails = action.payload;
      state.revisionDetailsSaving = false;
    },
    updateRevisionPackSettingsError(state) {
      state.revisionDetailsSaving = false;
    },

    // update pack revision grouping
    updateRevisionPackGrouping(state) {
      state.revisionDetailsSaving = true;
    },
    updateRevisionPackGroupingSuccess(state, action) {
      state.revisionDetails = action.payload;
      state.revisionDetailsSaving = false;
    },
    updateRevisionPackGroupingError(state) {
      state.revisionDetailsSaving = false;
    },

    // add layer to revision pack
    addRevisionPackLayer(state) {
      state.revisionDetailsSaving = true;
    },
    addRevisionPackLayerSuccess(state, action) {
      state.revisionDetails = action.payload;
      state.revisionDetailsSaving = false;
    },
    addRevisionPackLayerError(state) {
      state.revisionDetailsSaving = false;
    },

    // update layer in revision pack
    updateRevisionPackLayer(state) {
      state.revisionDetailsSaving = true;
    },
    updateRevisionPackLayerSuccess(state, action) {
      state.revisionDetails = action.payload;
      state.revisionDetailsSaving = false;
    },
    updateRevisionPackLayerError(state) {
      state.revisionDetailsSaving = false;
    },

    // remove layer from revision pack
    removeRevisionPackLayer(state) {
      state.revisionDetailsSaving = true;
    },
    removeRevisionPackLayerSuccess(state, action) {
      state.revisionDetails = action.payload;
      state.revisionDetailsSaving = false;
    },
    removeRevisionPackLayerError(state) {
      state.revisionDetailsSaving = false;
    },

    // add container to revision pack
    addRevisionPackContainer(state) {
      state.revisionDetailsSaving = true;
    },
    addRevisionPackContainerSuccess(state, action) {
      state.revisionDetails = action.payload;
      state.revisionDetailsSaving = false;
    },
    addRevisionPackContainerError(state) {
      state.revisionDetailsSaving = false;
    },

    // remove container from revision pack
    removeRevisionPackContainer(state) {
      state.revisionDetailsSaving = true;
    },
    removeRevisionPackContainerSuccess(state, action) {
      state.revisionDetails = action.payload;
      state.revisionDetailsSaving = false;
    },
    removeRevisionPackContainerError(state) {
      state.revisionDetailsSaving = false;
    },

    // update pack revision product count
    updateRevisionPartQty(state) {
      state.revisionDetailsSaving = true;
    },
    updateRevisionPartQtySuccess(state, action) {
      state.revisionDetails = action.payload;
      state.revisionDetailsSaving = false;
    },
    updateRevisionPartQtyError(state) {
      state.revisionDetailsSaving = false;
    },

    // load revision products list
    loadRevisionProducts(state) {
      state.revisionProducts = {};
      state.revisionProductsLoaded = false;
      state.revisionProductsLoading = true;
      state.revisionProductsLoadError = null;
    },
    loadRevisionProductsSuccess(state, action) {
      state.revisionProducts = action.payload;
      state.revisionProductsLoaded = true;
      state.revisionProductsLoading = false;
    },
    loadRevisionProductsError(state, action) {
      state.revisionProductsLoadError = action.payload || null;
      state.revisionProductsLoading = false;
    },

    // load pack types list
    loadPackTypes(state) {
      state.packTypes = [];
      state.packTypesLoaded = false;
      state.packTypesLoading = true;
      state.packTypesLoadError = null;
    },
    loadPackTypesSuccess(state, action) {
      state.packTypes = action.payload;
      state.packTypesLoaded = true;
      state.packTypesLoading = false;
    },
    loadPackTypesError(state, action) {
      state.packTypesLoadError = action.payload || null;
      state.packTypesLoading = false;
    },

    // load procedures list
    loadProcedures(state) {
      state.procedures = [];
      state.proceduresLoaded = false;
      state.proceduresLoading = true;
      state.proceduresLoadError = null;
    },
    loadProceduresSuccess(state, action) {
      state.procedures = action.payload;
      state.proceduresLoaded = true;
      state.proceduresLoading = false;
    },
    loadProceduresError(state, action) {
      state.proceduresLoadError = action.payload || null;
      state.proceduresLoading = false;
    },

    // load pack templates list
    loadPackTemplates(state) {
      state.packTemplates = {};
      state.packTemplatesLoaded = false;
      state.packTemplatesLoading = true;
      state.packTemplatesLoadError = null;
    },
    loadPackTemplatesSuccess(state, action) {
      state.packTemplates = action.payload;
      state.packTemplatesLoaded = true;
      state.packTemplatesLoading = false;
    },
    loadPackTemplatesError(state, action) {
      state.packTemplatesLoadError = action.payload || null;
      state.packTemplatesLoading = false;
    },

    // reset store
    resetStore: () => initialState,
  },
});

export const { actions, reducer, name: sliceKey } = packsSlice;
