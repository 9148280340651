import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  InteractionRequiredAuthError,
  PublicClientApplication,
} from '@azure/msal-browser';
import camelCase from 'lodash/camelCase';

import mm from 'utils/middleMan';
import request from 'utils/request';
import { msalConfig } from 'utils/sso';

import { actions as settingsActions } from 'app/data/settings/slice';

import { actions } from './slice';

const appScopes = [
  'User.Read',
  'Directory.AccessAsUser.All',
  'email',
  'profile',
];

export function* getUserDetails(username, token) {
  try {
    const userDetail = yield call(request, '/api/user/me', {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    });

    if (!userDetail.salesforce) {
      // User is not authorized to access this app
      yield put(actions.signInError('unauthorized'));
    } else {
      // Store token in local/session for future visits
      yield call(mm.storagePut, 'username', username, 'local');

      // Signal success
      yield put(actions.signInSuccess({ ...userDetail.salesforce, token }));
    }
  } catch {
    // Token error
    yield put(actions.signInError('tokenApi'));
  }
}

export function* signInSaga() {
  const msalInstance = new PublicClientApplication(msalConfig);

  // Try logging in
  try {
    const loginResponse = yield msalInstance.loginPopup({
      scopes: appScopes,
    });

    const {
      idToken,
      account: { username },
    } = loginResponse;

    // Try acquiring token after login
    yield call(getUserDetails, username, idToken);
  } catch (error) {
    // Login error
    yield put(actions.signInError(camelCase(error.errorCode)));
  }
}

export function* checkUserSaga() {
  try {
    // fetch user details from local/session storage
    const username = yield call(mm.checkAllStorage, { key: 'username' });
    const msalInstance = new PublicClientApplication(msalConfig);
    const currentAccount = msalInstance.getAccountByUsername(username);

    try {
      // Try getting token silently
      const silentResponse = yield msalInstance.acquireTokenSilent({
        scopes: appScopes,
        account: currentAccount,
        forceRefresh: false,
      });

      // Token obtained successfully
      yield call(getUserDetails, username, silentResponse.idToken);
    } catch (silentError) {
      // Catch error from getting token silently
      if (silentError instanceof InteractionRequiredAuthError) {
        // Fallback to interaction when able/relevant
        try {
          const popupResponse = yield msalInstance.acquireTokenPopup({
            scopes: appScopes,
            loginHint: currentAccount.username,
          });

          // Token obtained successfully
          yield call(getUserDetails, username, popupResponse.idToken);
        } catch (popupError) {
          yield put(actions.signInError(camelCase(popupError.errorCode)));
        }
      } else {
        yield put(actions.signInError(camelCase(silentError.errorCode)));
      }
    }
  } catch {
    yield put(actions.signInError());
  }
}

export function* signOutSaga() {
  // clear local/session storage
  yield call(mm.deleteAll, {});

  // reset user store first, to kick user out to login
  yield put(actions.resetStore());

  // reset other stores as needed
  yield put(settingsActions.resetStore());

  // handle SSO logout
  // const msalInstance = new PublicClientApplication(msalConfig);
  // yield msalInstance.logoutPopup();
}

export function* userSaga() {
  yield all([
    takeLatest(actions.signIn.type, signInSaga),
    takeLatest(actions.checkUser.type, checkUserSaga),
    takeLatest(actions.signOut.type, signOutSaga),
  ]);
}
