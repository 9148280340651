import styled from 'styled-components/macro';
import getPropStyles from 'styles/getPropStyles';

const H3 = styled.h3`
  font-size: 13px;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 0.5px;
  margin: 0;
  ${p => getPropStyles(p)}
`;

export default H3;
