import { all, call, takeLatest } from 'redux-saga/effects';
import { requestDetails } from 'utils/request';

import { actions } from './slice';

export function* loadSegmentsSaga() {
  yield call(requestDetails, {
    endpoint: '/api/dw/product/insightSegments',
    onError: ({ error }) => actions.loadSegmentsError(error),
    onSuccess: ({ details }) => actions.loadSegmentsSuccess(details),
  });
}

export function* loadSegmentDetailsSaga({ payload: { accountId, segmentId } }) {
  yield call(requestDetails, {
    endpoint: `/api/dw/products?insightSegment=${segmentId}&accountId=${accountId}`,
    onError: ({ error }) => actions.loadSegmentDetailsError(error),
    onSuccess: ({ details }) =>
      actions.loadSegmentDetailsSuccess({
        id: segmentId,
        accountId: accountId,
        ...details,
      }),
  });
}

export function* productsSaga() {
  yield all([
    takeLatest(actions.loadSegments.type, loadSegmentsSaga),
    takeLatest(actions.loadSegmentDetails.type, loadSegmentDetailsSaga),
  ]);
}
