import React from 'react';
import styled from 'styled-components/macro';
import hotToast, { Toaster, ToastBar } from 'react-hot-toast';

import { H3 } from 'app/components/Typography';

const toastColors = {
  blank: {
    background: 'astronaut600',
    cancelIcon: 'charlotte600',
    font: 'manatee200',
  },
  error: {
    background: 'coral600',
    cancelIcon: 'manatee200',
    font: 'manatee200',
  },
  success: {
    background: 'limerick200',
    cancelIcon: 'bedrock900',
    font: 'bedrock900',
  },
};

const ToastWrapper = styled.div`
  max-width: 480px;
  padding: 16px 96px 16px 20px;
  border-radius: 4px;
  background: ${p => p.theme.color[p.toastColor.background]};
  transition: all 200ms ease-out;

  ${H3} {
    color: ${p => p.theme.color[p.toastColor.font]};
  }
`;

const ToastCancelButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 100%;
  background: none;
  border: none;
  border-radius: 0 4px 4px 0;
  outline: none;
  cursor: pointer;
  transition: all 300ms ease;
  color: ${p => p.theme.color[p.iconColor]};

  &:hover {
    text-decoration: underline;
  }

  ${H3} {
    display: block;
    color: ${p => p.theme.color[p.iconColor]};
  }
`;

const ToastNotifications = () => {
  return (
    <Toaster
      position="bottom-center"
      toastOptions={{
        // Define default options
        className: '',
        duration: 4000,
        style: {
          boxShadow: 'none',
          padding: 0,
        },

        // Default options for specific types
        error: {
          duration: Infinity,
        },
      }}
    >
      {t => (
        <ToastBar toast={t}>
          {() => {
            const toastColor = toastColors[t.type] || toastColors[0];

            return (
              <ToastWrapper {...{ toastColor }}>
                <H3 bold>{t.message}</H3>
                <ToastCancelButton
                  iconColor={toastColor.cancelIcon}
                  onClick={() => hotToast.dismiss(t.id)}
                  type="button"
                >
                  <H3 as="span" bold>
                    Dismiss
                  </H3>
                </ToastCancelButton>
              </ToastWrapper>
            );
          }}
        </ToastBar>
      )}
    </Toaster>
  );
};

export default ToastNotifications;
