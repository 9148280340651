import { createSelector } from '@reduxjs/toolkit';

import { initialState, sliceKey } from './slice';

const selectDomain = state => state[sliceKey] || initialState;

export const selectAccounts = createSelector(
  [selectDomain],
  state => state.accounts,
);

export const selectAccountsLoaded = createSelector(
  [selectDomain],
  state => state.accountsLoaded,
);

export const selectAccountsLoading = createSelector(
  [selectDomain],
  state => state.accountsLoading,
);

export const selectAccountsLoadError = createSelector(
  [selectDomain],
  state => state.accountsLoadError,
);

export const selectAccountDetails = createSelector(
  [selectDomain],
  state => state.accountDetails,
);

export const selectAccountDetailsId = createSelector(
  [selectDomain],
  state => state.accountDetails.id,
);

export const selectAccountDetailsLoaded = createSelector(
  [selectDomain],
  state => state.accountDetailsLoaded,
);

export const selectAccountDetailsLoading = createSelector(
  [selectDomain],
  state => state.accountDetailsLoading,
);

export const selectAccountDetailsLoadError = createSelector(
  [selectDomain],
  state => state.accountDetailsLoadError,
);

export const selectAccountOpportunities = createSelector(
  [selectDomain],
  state => state.accountOpportunities,
);

export const selectAccountOpportunitiesLoaded = createSelector(
  [selectDomain],
  state => state.accountOpportunitiesLoaded,
);

export const selectAccountOpportunitiesLoading = createSelector(
  [selectDomain],
  state => state.accountOpportunitiesLoading,
);

export const selectAccountOpportunitiesLoadError = createSelector(
  [selectDomain],
  state => state.accountOpportunitiesLoadError,
);

export const selectAccountProfile = createSelector(
  [selectDomain],
  state => state.accountProfile,
);

export const selectAccountProfileId = createSelector(
  [selectDomain],
  state => state.accountProfile.id,
);

export const selectAccountProfileContacts = createSelector(
  [selectDomain],
  state => state.accountProfile.contacts,
);

export const selectAccountProfileContracts = createSelector(
  [selectDomain],
  state => state.accountProfile.contracts,
);

export const selectAccountProfileCompetitors = createSelector(
  [selectDomain],
  state => state.accountProfile.competitors,
);

export const selectAccountProfileOrders = createSelector(
  [selectDomain],
  state => state.accountProfile.orders,
);

export const selectAccountProfileProcedures = createSelector(
  [selectDomain],
  state => state.accountProfile.procedures,
);

export const selectAccountProfileLoaded = createSelector(
  [selectDomain],
  state => state.accountProfileLoaded,
);

export const selectAccountProfileLoading = createSelector(
  [selectDomain],
  state => state.accountProfileLoading,
);

export const selectAccountProfileLoadError = createSelector(
  [selectDomain],
  state => state.accountProfileLoadError,
);

export const selectAccountProfileSaving = createSelector(
  [selectDomain],
  state => state.accountProfileSaving,
);

export const selectRecentAccounts = createSelector(
  [selectDomain],
  state => state.recentAccounts,
);

export const selectRecentAccountsLoaded = createSelector(
  [selectDomain],
  state => state.recentAccountsLoaded,
);

export const selectRecentAccountsLoading = createSelector(
  [selectDomain],
  state => state.recentAccountsLoading,
);

export const selectRecentAccountsLoadError = createSelector(
  [selectDomain],
  state => state.recentAccountsLoadError,
);
