// clear data from storage
const clearStorage = ({ key }) => {
  return new Promise(resolve => {
    window.localStorage.removeItem(key);
    window.sessionStorage.removeItem(key);
    resolve('success');
  });
};

// clear data from storage
const deleteAll = () => {
  return new Promise(resolve => {
    window.localStorage.clear();
    window.sessionStorage.clear();
    resolve('success');
  });
};

const checkAllStorage = ({ key }) => {
  return new Promise((resolve, reject) => {
    storageGet(key, window.localStorage).then(localResult => {
      if (!localResult) {
        storageGet(key, window.sessionStorage).then(sessionResult => {
          if (!sessionResult) {
            reject(false); // eslint-disable-line
          } else {
            resolve(sessionResult);
          }
        });
      } else {
        resolve(localResult);
      }
    });
  });
};

/**
 * Gets Data from storage
 * @param  {string} key The Key for the store
 * @return {Promise}
 */
const storageGet = (key, storage) => {
  return new Promise(resolve => {
    let data = storage.getItem(key) ? storage.getItem(key) : false;
    if (data !== 'undefined') {
      data = JSON.parse(data);
    } else {
      data = false;
    }
    resolve(data);
  });
};

/**
 * Writes Data to storage
 * @param  {string} key The Key for the store
 * @param { object||array } [data] The data to store
 * @param { string 'local'|'session'} [storage] The source
 * @return {Promise}
 */
const storagePut = (key, data, storage) => {
  const realStorage =
    storage === 'local' ? window.localStorage : window.sessionStorage;
  return new Promise(resolve => {
    realStorage.setItem(key, JSON.stringify(data));
    resolve(data);
  });
};

const exports = {
  clearStorage,
  checkAllStorage,
  deleteAll,
  storageGet,
  storagePut,
};

export default exports;
