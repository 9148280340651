import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  accounts: [],
  accountsLoaded: false,
  accountsLoading: false,
  accountsLoadError: null,
  accountDetails: {},
  accountDetailsLoaded: false,
  accountDetailsLoading: false,
  accountDetailsLoadError: null,
  accountOpportunities: {},
  accountOpportunitiesLoaded: false,
  accountOpportunitiesLoading: false,
  accountOpportunitiesLoadError: null,
  accountProfile: {},
  accountProfileLoaded: false,
  accountProfileLoading: false,
  accountProfileLoadError: null,
  accountProfileSaving: false,
  recentAccounts: [],
  recentAccountsLoaded: false,
  recentAccountsLoading: false,
  recentAccountsLoadError: null,
};

const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    // load accounts
    loadAccounts(state) {
      state.accounts = [];
      state.accountsLoaded = false;
      state.accountsLoading = true;
      state.accountsLoadError = null;
    },
    loadAccountsSuccess(state, action) {
      state.accounts = action.payload;
      state.accountsLoaded = true;
      state.accountsLoading = false;
    },
    loadAccountsError(state, action) {
      state.accountsLoadError = action.payload || null;
      state.accountsLoading = false;
    },

    // load account details
    loadAccountDetails(state) {
      state.accountDetails = {};
      state.accountDetailsLoaded = false;
      state.accountDetailsLoading = true;
      state.accountDetailsLoadError = null;
    },
    loadAccountDetailsSuccess(state, action) {
      state.accountDetails = action.payload;
      state.accountDetailsLoaded = true;
      state.accountDetailsLoading = false;
    },
    loadAccountDetailsError(state, action) {
      state.accountDetailsLoadError = action.payload || null;
      state.accountDetailsLoading = false;
    },

    // load account opportunities
    loadAccountOpportunities(state) {
      state.accountOpportunities = {};
      state.accountOpportunitiesLoaded = false;
      state.accountOpportunitiesLoading = true;
      state.accountOpportunitiesLoadError = null;
    },
    loadAccountOpportunitiesSuccess(state, action) {
      state.accountOpportunities = action.payload;
      state.accountOpportunitiesLoaded = true;
      state.accountOpportunitiesLoading = false;
    },
    loadAccountOpportunitiesError(state, action) {
      state.accountOpportunitiesLoadError = action.payload || null;
      state.accountOpportunitiesLoading = false;
    },

    // clear account opportunities
    clearAccountOpportunities(state) {
      state.accountOpportunities = {};
      state.accountOpportunitiesLoaded = false;
      state.accountOpportunitiesLoading = false;
      state.accountOpportunitiesLoadError = null;
    },

    // load account profile
    loadAccountProfile(state) {
      state.accountProfile = {};
      state.accountProfileLoaded = false;
      state.accountProfileLoading = true;
      state.accountProfileLoadError = null;
    },
    loadAccountProfileSuccess(state, action) {
      state.accountProfile = action.payload;
      state.accountProfileLoaded = true;
      state.accountProfileLoading = false;
    },
    loadAccountProfileError(state, action) {
      state.accountProfileLoadError = action.payload || null;
      state.accountProfileLoading = false;
    },

    // load recently viewed accounts
    loadRecentAccounts(state) {
      state.recentAccounts = [];
      state.recentAccountsLoaded = false;
      state.recentAccountsLoading = true;
      state.recentAccountsLoadError = null;
    },
    loadRecentAccountsSuccess(state, action) {
      state.recentAccounts = action.payload;
      state.recentAccountsLoaded = true;
      state.recentAccountsLoading = false;
    },
    loadRecentAccountsError(state, action) {
      state.recentAccountsLoadError = action.payload || null;
      state.recentAccountsLoading = false;
    },

    // post recently viewed accounts
    saveRecentAccounts(state) {
      state.recentAccounts = [];
      state.recentAccountsLoaded = false;
      state.recentAccountsLoading = true;
      state.recentAccountsLoadError = null;
    },
    saveRecentAccountsSuccess(state, action) {
      state.recentAccounts = action.payload;
      state.recentAccountsLoaded = true;
      state.recentAccountsLoading = false;
    },
    saveRecentAccountsError(state, action) {
      state.recentAccountsLoadError = action.payload || null;
      state.recentAccountsLoading = false;
    },

    // post new account profile contact
    addAccountProfileContact(state) {
      state.accountProfileSaving = true;
    },
    addAccountProfileContactSuccess(state, action) {
      state.accountProfileSaving = false;
      state.accountProfile = action.payload;
    },
    addAccountProfileContactError(state) {
      state.accountProfileSaving = false;
    },

    // edit existing account profile contact
    editAccountProfileContact(state) {
      state.accountProfileSaving = true;
    },
    editAccountProfileContactSuccess(state, action) {
      state.accountProfileSaving = false;
      state.accountProfile = action.payload;
    },
    editAccountProfileContactError(state) {
      state.accountProfileSaving = false;
    },

    // delete existing account profile contact
    deleteAccountProfileContact(state) {
      state.accountProfileSaving = true;
    },
    deleteAccountProfileContactSuccess(state, action) {
      state.accountProfileSaving = false;
      state.accountProfile = action.payload;
    },
    deleteAccountProfileContactError(state) {
      state.accountProfileSaving = false;
    },

    // post new account profile competitor
    addAccountProfileCompetitor(state) {
      state.accountProfileSaving = true;
    },
    addAccountProfileCompetitorSuccess(state, action) {
      state.accountProfileSaving = false;
      state.accountProfile = action.payload;
    },
    addAccountProfileCompetitorError(state) {
      state.accountProfileSaving = false;
    },

    // edit existing account profile competitor
    editAccountProfileCompetitor(state) {
      state.accountProfileSaving = true;
    },
    editAccountProfileCompetitorSuccess(state, action) {
      state.accountProfileSaving = false;
      state.accountProfile = action.payload;
    },
    editAccountProfileCompetitorError(state) {
      state.accountProfileSaving = false;
    },

    // delete existing account profile competitor
    deleteAccountProfileCompetitor(state) {
      state.accountProfileSaving = true;
    },
    deleteAccountProfileCompetitorSuccess(state, action) {
      state.accountProfileSaving = false;
      state.accountProfile = action.payload;
    },
    deleteAccountProfileCompetitorError(state) {
      state.accountProfileSaving = false;
    },

    // reset store
    resetStore: () => initialState,
  },
});

export const { actions, reducer, name: sliceKey } = accountsSlice;
