import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  pickLists: {},
  pickListsLoading: false,
  pickListsLoadError: null,
};

const pickListsSlice = createSlice({
  name: 'pickLists',
  initialState,
  reducers: {
    // load pickLists
    loadPickLists(state) {
      state.pickLists = {};
      state.pickListsLoading = true;
      state.pickListsLoadError = null;
    },
    loadPickListsSuccess(state, action) {
      state.pickLists = action.payload;
      state.pickListsLoading = false;
    },
    loadPickListsError(state, action) {
      state.pickListsLoadError = action.payload || null;
      state.pickListsLoading = false;
    },

    // reset store
    resetStore: () => initialState,
  },
});

export const { actions, reducer, name: sliceKey } = pickListsSlice;
