import React from 'react';
import PropTypes from 'prop-types';

const Img = ({ alt, src, className, ...props }) => {
  return <img alt={alt} src={src} className={className} {...props} />;
};

Img.propTypes = {
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
};

export default Img;
