import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  user: {},
  userAuthenticated: false,
  userLoading: true,
  userLoadError: null,
  userSignedOut: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // auth user (login)
    signIn(state) {
      state.user = {};
      state.userAuthenticated = false;
      state.userLoading = true;
      state.userLoadError = null;
    },
    signInSuccess(state, action) {
      state.user = action.payload;
      state.userAuthenticated = true;
      state.userLoading = false;
      state.userSignedOut = false;
    },
    signInError(state, action) {
      state.userLoading = false;
      state.userLoadError = action.payload || null;
    },

    // check user (verifying token)
    checkUser(state) {
      state.user = {};
      state.userAuthenticated = false;
      state.userLoading = true;
      state.userLoadError = null;
    },

    // sign out
    signOut(state) {
      state.userLoading = true;
    },

    // reset store
    resetStore: () => ({
      ...initialState,
      userSignedOut: true,
    }),
  },
});

export const { actions, reducer, name: sliceKey } = userSlice;
