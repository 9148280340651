import { createGlobalStyle } from 'styled-components/macro';

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    color: ${p => p.theme.color.bedrock800};
    background-color: ${p => p.theme.color.manatee200};
    transition: background-color 200ms ease;

    &.bg--manatee400 {
      background-color: ${p => p.theme.color.manatee400};
    }
  }

  body,
  button,
  input,
  textarea {
    font-family: ${p => p.theme.fonts.gilroyMedium};
    font-size: 13px;
    letter-spacing: 0.3px;
    line-height: 1.6;
  }

  /* ensures app fills viewport */
  html,
  body,
  #root {
    display: flex;
    width: 100%;
    min-height: 100%;
  }

  /* https://a11yproject.com/posts/how-to-hide-content/ */
  .visually-hidden {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap; /* added line */
  }
`;
